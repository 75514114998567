"use strict";
// import { gsap } from "gsap";
// import $ from "jquery";
Object.defineProperty(exports, "__esModule", { value: true });
exports.onFaqItemClick = void 0;
const sliders_1 = require("./sliders");
const menu_1 = require("./menu");
const header_1 = require("./header");
/**
 * globalVariables variabili globali utilizzate all'interno del sito
 */
let globalVariables = {
    lastKnownScrollPosition: 0,
    headerShadowTheshold: 50,
    faqDataTargetPrefix: '#collapse-',
    // TODO qui possono andarci altre proprietà utili
    // dopo averle prima definite nell'interfaccia `GlobalVariables` in models.ts
};
// al caricamento completo della pagina si chiama
// la funzione onDocumentLoaded
document.addEventListener('DOMContentLoaded', function () {
    onDocumentLoaded();
});
// $(() => {
// 	onDocumentLoaded();
// });
/**
 * chiamata quando viene finito di caricare il document
 */
function onDocumentLoaded() {
    // si inizializzano gli slider
    (0, sliders_1.initializeRecensioniSlider)();
    (0, sliders_1.initializePartnerSlider)();
    (0, sliders_1.initializePhotogallerySlider)();
    (0, sliders_1.initializeStoriaSlider)();
    // si registra il listener per lo scroll
    document.addEventListener('scroll', (ev) => {
        doOnScroll(ev);
    });
    // si registrano i listener per l'accordion
    const faqBtns = document.querySelectorAll('.faq-btn');
    faqBtns.forEach(btn => {
        btn.addEventListener('click', (ev) => {
            onFaqItemClick(ev);
        });
    });
    (0, menu_1.enableThirdLevelMenu)();
    configureGalleryModal();
}
/**
 * Chiamato allo scroll della pagina
 *
 * @param ev {@link Event}
 */
function doOnScroll(ev) {
    const oldScrollPosition = globalVariables.lastKnownScrollPosition;
    const newScrollPosition = window.scrollY;
    // per ottimizzazione si chiama changeHeaderOnScroll solo quando
    // si oltrepassa la soglia globalVariables.headerShadowTheshold definita
    if ((oldScrollPosition > globalVariables.headerShadowTheshold && newScrollPosition < globalVariables.headerShadowTheshold)
        || (oldScrollPosition <= globalVariables.headerShadowTheshold && newScrollPosition >= globalVariables.headerShadowTheshold)) {
        (0, header_1.changeHeaderProperties)(newScrollPosition >= globalVariables.headerShadowTheshold);
    }
    // NOTA: qui di seguito possono andarci altri metodi da chiamare allo scroll
    // si aggiorna lastKnownScrollPosition
    globalVariables.lastKnownScrollPosition = newScrollPosition;
}
/**
 * Chiamato al click di un button dell'accordion delle faq
 *
 * @param ev Event
 * @returns
 */
function onFaqItemClick(ev) {
    const dataTarget = ev.currentTarget.getAttribute('data-target');
    if (!dataTarget) {
        console.error('on faq button click: missing dataTarget');
        return;
    }
    const targetId = parseInt(dataTarget.replace(globalVariables.faqDataTargetPrefix, ''), 10);
    // si deve ruotare icona freccia e cambiarle colore
    const icona = document.querySelector(`#heading-${targetId} .fa-angle-down`);
    if (!icona) {
        console.error('onFaqItemClick: icon missing');
    }
    if (ev.currentTarget.getAttribute('aria-expanded') == 'true') {
        // stiamo chiudendo accordion
        icona.className = icona.className.replace(' fa-rotate-180 color_yellow_dark', '');
    }
    else {
        // stiamo aprendo accordion
        icona.className += ' fa-rotate-180 color_yellow_dark';
        // si devono cambiare le icone di eventuali accordion aperti
        const altreIcone = document.querySelectorAll(`.fa-angle-down:not(#heading-${targetId} .fa-angle-down)`);
        altreIcone.forEach(i => {
            i.className = i.className.replace(' fa-rotate-180 color_yellow_dark', '');
        });
    }
}
exports.onFaqItemClick = onFaqItemClick;
/**
 * configura la modal della gallery mettendoci dentro l'immagine specifica da visualizzare
 */
function configureGalleryModal() {
    const modalEls = document.querySelectorAll('.galleriaLink');
    modalEls.forEach(el => {
        // ad ogni link associo event listener al click
        el.addEventListener('click', (ev) => {
            // recupero dal link html l'attributo data-url
            const urlImg = ev.currentTarget.getAttribute('data-url');
            if (!urlImg) {
                console.error('configureGalleryModal missing urlImg');
                return;
            }
            // metto la url ricavata nell'img dentro la modal
            const imgEl = document.querySelector('#imgModal');
            if (!imgEl) {
                console.error('configureGalleryModal missing imgEl');
                return;
            }
            imgEl.src = urlImg;
        });
    });
}
